@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
	--app-header-height: 60px;
	--app-footer-height: 120px;
	--app-aside-bar-width: 360px;
	--layout-wrap-width: 1920px;
	--compact-wrap-width: var(--compact-wrap-sm-width); /*was 1600px*/
	--compact-wrap-sm-width: 1200px;
	--compact-wrap-xs-width: 900px;
	--compact-wrap-xxs-width: 600px;
	--main-content-padding: 30px;
	--menu-item-h-padding: 20px;
	--menu-link-icon-gap: 10px;
	--ant-bgr-reset: transparent;
	--accent-color: #207662;
	--accent-color-text: #fff;
	--accent-color-hover: #4f8d7f;
	--accent-color2: #0044e3;
	--accent-color2-text: #fff;
	--accent-color2-hover: #1677ff;
	--link-color: var(--accent-color);
	--link-color-hover: var(--accent-color-hover);
	--link-color2: #666;
	--link-color2-hover: #333;
	--main-txt: #000000;
	--txt-disabled: #888;
	--light-gray: #f0f0f0;
	--light-gray-hover: #e2e2e2;
	--dark-gray: #d6d6d6;
	--extra-dark-gray: #202020;
	--theme-light-gray: #efefef;
	--footer-bgr: #E0E0E0;
	--main-font-size: 14px;
	--main-bgr: #fff;
	--border-color: rgba(0,0,0,0.2);
	--h-layout-padding: 30px;
	--v-layout-padding: 10px;
	--page-spinner-bgr: rgba(215, 215, 215, 0.5);
	--page-spinner-dot-color: var(--accent-color);
	--page-spinner-circle-bgr: rgba(255, 255, 255, 0.8);
	--txt-xxsmall: 0.4rem;
	--txt-xsmall: 0.6rem;
	--txt-small: 0.8rem;
	--txt-normal: 1rem;
	--txt-large: 1.2rem;
	--txt-xlarge: 1.6rem;
	--txt-xxlarge: 2.0rem;
	--comment-bubble-bgr: rgba(0,0,0,0.06);
	--comment-el-mention-bgr: #cee4e5;

	/* mobile variables */
	--mobile-footer-height: 66px;
	--mobile-footer-bgr: #fafafa;

	--mobile-context-wrap-link-bgr-hover: rgba(0,0,0,0.06);
	--mobile-context-wrap-link-padding: 20px 16px;
	--sticky-to-bottom-bar: var(--mobile-footer-height);
}

/* ant library MAIN variables */
[class*='css-var-'] {
	--ant-font-family: "Open Sans", sans-serif;
	--ant-font-size: var(--main-font-size);
	--ant-border-radius: 4px;
	--ant-border-radius-xs: 2px;
	--ant-border-radius-sm: 4px;
	--ant-border-radius-lg: 4px;
	--ant-border-radius-outer: 4px;
	--ant-color-text-placeholder: #9F9F9F;
}


	/* ant library FORM variables */
	[class*='css-var-'].ant-input {
		--ant-input-active-border-color: rgba(0,0,0,0.6);
		--ant-input-hover-border-color: rgba(0,0,0,0.4);
		--ant-input-active-shadow: rgba(0,0,0,0.4);
	}


	/* ant library BUTTONS variables */
	[class*='css-var-'].ant-btn {
		/* primary */
		--ant-button-primary-color: var(--accent-color-text);
		--ant-color-primary: var(--accent-color);
		--ant-button-primary-shadow: none;
		--ant-color-primary-hover: var(--accent-color-hover);
		--ant-color-primary-active: var(--accent-color);
		--ant-color-primary-text-hover: var(--accent-color-hover);
		--ant-color-primary-text: var(--accent-color);
		--ant-color-primary-text-active: var(--accent-color);
		/* default */
		--ant-button-default-bg: #fff;
		--ant-button-default-shadow: none;
		/* --ant-button-default-border-color: var(--accent-color); */
		/* --ant-button-default-color: var(--accent-color); */
		--ant-button-default-hover-color: initial;
		--ant-button-default-hover-border-color: var(--accent-color-hover);
		--ant-line-width: 2px;
	}


html {
	margin: 0;
	font-size: var(--main-font-size);
}

body {
	margin: 0;
	color: var(--main-txt);
	font-weight: 400;
	font-style: normal;
	font-size: var(--main-font-size);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/** Links */
a,
a:not([href]):not([tabindex]),
.force-link,
.default-link,
.user-menu .default-link {
	color: var(--link-color);
	text-decoration: none;
	cursor: pointer;
}

	a:visited {
	}

	a:hover,
	.force-link:hover {
		color: var(--link-color-hover);
		text-decoration: none;
	}

	a:focus {
		/*outline: 1px dotted;*/
	}

	a:active {
	}


.link-color2 {
	color: var(--link-color2);
}	
.link-color2:hover {
	color: var(--link-color2-hover);
}	
/**********************************************************************************/
/******************** Theme Specific ********************/
/**********************************************************************************/

.ant-layout,
.ant-layout-header,
.ant-layout-footer,
.ant-layout .ant-layout-sider {
	background-color: var(--ant-bgr-reset);
}


.sticky-el,
.ant-affix {
	background-color: var(--main-bgr);
}


/******************** Utility ********************/
.layout-wrap {
	max-width: var(--layout-wrap-width);
	max-width: var(--compact-wrap-width);
	margin-left: auto;
	margin-right: auto;
}

.compact-wrap {
	max-width: var(--compact-wrap-width);
	margin-left: auto;
	margin-right: auto;
}

	.compact-wrap.compact-sm {
		max-width: var(--compact-wrap-sm-width);
	}

	.compact-wrap.compact-xs {
		max-width: var(--compact-wrap-xs-width);
	}

	.compact-wrap.compact-xxs {
		max-width: var(--compact-wrap-xxs-width);
	}

/**/
.ficon {
	--ficon-space: 10px;
	border: none;
}

.fspace-r {
	margin-right: var(--ficon-space);
}

.fspace-l {
	margin-left: var(--ficon-space);
}

/**/
.txt-xxsmall {
	font-size: var(--txt-xxsmall);
}

.txt-xsmall {
	font-size: var(--txt-xsmall);
}

.txt-small {
	font-size: var(--txt-small);
}

.txt-normal {
	font-size: var(--txt-normal);
}

.txt-large {
	font-size: var(--txt-large);
}

.txt-xlarge {
	font-size: var(--txt-xlarge);
}

.txt-xxlarge {
	font-size: var(--txt-xxlarge);
}


.hidden {display: none !important;}

/******************** Stars ********************/
.stars-outer {
	display: inline-block;
	position: relative;
}

	.stars-outer::before {
		content: "\ea12 \ea12 \ea12 \ea12 \ea12 ";
	}

.stars-inner {
	position: absolute;
	top: 0;
	left: 0;
	white-space: nowrap;
	overflow: hidden;
	width: 0;
}

	.stars-inner::before {
		content: "\e9fa \e9fa \e9fa \e9fa \e9fa";
		color: #f8ce0b;
	}
/*<<*/


/******************** Common ********************/

/* link */
a {
	color: var(--accent-color);
}

	a:hover {
		color: var(--accent-color-hover);
	}

.link {
	cursor: pointer;
}

/* buttons */
.btn-h-large {
	padding-inline: 30px;
}

.btn-h-xlarge {
	padding-inline: 60px;
}

.action-btn,
[class*='css-var-'].action-btn {
	padding: 4px;
	font-size: 16px;
	line-height: 1;
}

	.action-btn.highlighted,
	[class*='css-var-'].action-btn.highlighted {
		color: var(--accent-color-text);
		background: var(--accent-color);
		height: auto;
		--ant-button-text-hover-bg: var(--accent-color-hover);
		--ant-color-text: var(--accent-color-text);
	}

/*btn text multiline extend for ant-btn*/
.btn-multiline.ant-btn {
	white-space: wrap;
	height: auto;
	/* align-items: start; */
	text-align: left;
}


.btn-text-clip .text-wrap {
	display: inline-block;
	max-width: 350px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.mobile-view-mode .btn-text-clip .text-wrap {
	max-width: 200px;
}

/**/

.page-title-wrap {
	margin-bottom: 15px;
}

/* transitions */
.trans-all {
	transition: all 250ms ease-in-out;
}

.trans-color-and-bgr,
.ant-drawer-content.mobile-context-wrap .ant-drawer-body .link {
	transition: background-color 250ms ease-in-out, color 250ms ease-in-out;
}

/******************** Project Home.tsx ********************/
.content-tabs-wrap {
	/* display: flex;
	gap: 3rem;
	align-items: center;
	justify-content: center; */
}
.content-tabs-wrap .ant-tabs-nav-list {
	width: 100%;
}

.content-tabs-wrap .ant-tabs-tab {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1 1;
	padding: 1rem;
	border: 1.5px solid transparent;
	background-color: var(--light-gray);
	transition: all 0.3s ease;
	color: var(--accent-color);
	border-radius: var(--ant-border-radius);
	border: 2px solid transparent;
}
.content-tabs-wrap .ant-tabs-tab:hover {
	background-color: var(--light-gray-hover);
}
.content-tabs-wrap .ant-tabs-tab-active {
	border-color: var(--accent-color);
}
.content-tabs-wrap .tab-text-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.content-tabs-wrap .ant-tabs-ink-bar {
	/* --ant-line-width-bold: 100%;
	border-radius: var(--ant-border-radius);
	height: var(--ant-line-width-bold);
	background-color: transparent;
	border: 2px solid var(--accent-color); */
	opacity: 0;
}
.content-tabs-wrap .ant-tabs-top > .ant-tabs-nav::before, 
.content-tabs-wrap .ant-tabs-bottom > .ant-tabs-nav::before, 
.content-tabs-wrap .ant-tabs-top > div > .ant-tabs-nav::before, 
.content-tabs-wrap .ant-tabs-bottom > div > .ant-tabs-nav::before {
	/* position: absolute;
	right: 0;
	left: 0;
	border-bottom: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border-secondary);
	content: ''; */
	opacity: 0;
}

/**/
.ant-tabs-dropdown-menu-item .tab-text-wrap {
	display: flex;
	gap: 10px;
	align-items: center;
}

.ant-tabs-nav-operations,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
	position: absolute;
	right: 0;
	background-color: var(--main-bgr);
	height: 100%;
	width: 40px;
}
/**/


.active-panel {
	border: 1.5px solid var(--accent-color);
}

.panel-button:hover {
	border: 1.5px solid var(--extra-dark-gray) !important;
	background-color: var(--dark-gray) !important;
}

.panel-button .anticon {
	margin-bottom: 0.2rem;
}

.btn-text {
	font-size: 0.8rem;
	text-align: center;
}

.project-pannel {
	padding-bottom: 1px;
	margin-bottom: 1rem;
	background-color: white;
	border: 1px solid var(--border-color);
}

.refine-text {
	font-size: 0.8rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 2.5rem;
}

.panel-collapse .ant-collapse-item:last-child {
	border-bottom: 1px solid var(--border-color);
}
/*<<*/


/******************** Projects Home.tsx ********************/
.trash-tab {
	color: black;
	border: none;
	font-size: 14px;
	transition: color 0.3s;
	border-radius: 0;
}

	.trash-tab:hover {
		color: var(--accent-color) !important;
	}

	.trash-tab.ant-tabs-tab-active {
		color: black !important;
		font-weight: 500;
		border-bottom: 2px solid var(--accent-color);
	}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--accent-color) !important;
}

.ant-tabs .ant-tabs-tab :hover {
	color: var(--accent-color) !important;
}

.ant-tabs-css-var {
	--ant-tabs-ink-bar-color: var(--accent-color) !important;
	--ant-tabs-item-hover-color: var(--accent-color) !important;
	--ant-tabs-item-active-color: var(--accent-color) !important;
}

/*<<*/

/******************** Action buttons ********************/
.action-button {
	cursor: pointer;
	font-size: 16px;
	border: none;
	background-color: transparent;
}
/*<<*/

/******************** Scroll to top button ********************/
.ant-float-btn-body:hover {
	background-color: var(--accent-color-hover) !important;
}

.anticon-vertical-align-top {
	color: #ddd;
}

.ant-float-btn-body {
	background-color: var(--accent-color) !important;
	color: #ddd;
}
/*<<*/
/**/
.pan-gray {
	background-color: var(--theme-light-gray);
	border-radius: 10px;
	padding: 20px;
}

.page-title,
.ant-typography.page-title {
	font-size: 20px;
	font-weight: 600;
	padding-bottom: 10px;
	margin-top: 0;
}

.accent-title {
	color: var(--accent-color);
	padding-bottom: 20px;
}

	.accent-title.title-h1 {
		font-size: 24px;
		font-weight: 600;
	}

x
/* page spinner */
.ant-spin .ant-spin-dot-item {
	background-color: var(--page-spinner-dot-color);
}

/* force page spinner to go fullscreen */
.full-page-spinner {
	background-color: var(--page-spinner-bgr);
	/* backdrop-filter: blur(2px); */
}

.ant-spin-nested-loading > div > .ant-spin.full-page-spinner {
	position: fixed;
	max-height: none;
	z-index: 400;
}

.full-page-spinner .ant-spin-dot {
	font-size: 20px;
	width: 1.2em;
	height: 1.2em;
}

.full-page-spinner .ant-spin-dot-item {
	width: 10px;
	height: 10px;
	transform: scale(.75);
}

.full-page-spinner .ant-spin-dot-spin {
	animation-duration: 1s;
}

/**/
.spinner-transparent {
	--page-spinner-bgr: transparent;
}

	.spinner-transparent .ant-spin-dot {
		border-radius: 100px;
		background-color: var(--page-spinner-circle-bgr);
		box-shadow: 0 0 14px 16px var(--page-spinner-circle-bgr);
	}




/* collapse block - skin-clean */
.ant-collapse.skin-clean > .ant-collapse-item > .ant-collapse-header {
	padding-inline: 0;
	font-weight: 700;
}

.ant-collapse.skin-clean > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
	padding: 0;
}

/*<<*/


/******************** app wrap ********************/
.app-wrap {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	min-height: calc(100vh - var(--app-footer-height));
}


	.app-wrap .app-wrap-inner {
	}
/*<<*/

/******************** app header ********************/
.app-header {
	height: var(--app-header-height); /*ant overwrite*/
	line-height: 1;
	padding: var(--v-layout-padding) var(--h-layout-padding);
	border-bottom: 1px solid var(--border-color);
	position: sticky;
	top: 0;
	z-index: 600;
	background-color: var(--main-bgr);
	box-shadow: 0 5px 30px 10px var(--main-bgr);
}

.app-logo img {
	max-width: 200px;
	height: auto;
}

.user-nav {
	padding: 10px;
}

.user-nav-dropdown {
	padding: 10px;
}

.user-thumb .ficon-circle {
	padding: 16px;
	border-radius: 100px;
	background-color: var(--light-gray);
}

/*<<*/

/******************** app main ********************/
.app-main {
	padding: var(--v-layout-padding) var(--h-layout-padding);
}

.content-wrap {
	min-height: calc(100svh - var(--app-header-height) - var(--app-footer-height));
	padding: var(--main-content-padding);
}

.main-search {
	min-height: 300px;
}

/* aside main menu */
.app-aside {
	min-width: var(--app-aside-bar-width) !important;	
}
.app-aside .app-aside-inner {
	position: sticky;
	top: calc(var(--app-header-height) + 20px);
}

.main-menu {
	/* position: sticky;
	top: calc(var(--app-header-height) + 20px); */
	border: none !important;
}

	.main-menu.ant-menu-vertical .ant-menu-item {
		height: 40px;
		line-height: 40px;
		padding-inline: var(--menu-item-h-padding);
		overflow: hidden;
		text-overflow: ellipsis;
		--menu-item-h-space: 0;
		margin-inline: var(--menu-item-h-space);
		margin-block: 10px;
		width: calc(100% - var(--menu-item-h-space));
		border-radius: 0 6px 6px 0;
	}

	.main-menu .menu-link {
		display: flex;
		align-items: center;
		gap: var(--menu-link-icon-gap);
	}

	.main-menu .menu-link-icon {
	}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
	/* background-color: var(--accent-color);
  color: var(--accent-color-text); */
	background-color: transparent;
	color: var(--main-txt);
	font-weight: 700;
}

/* quick-nav-aside - used for Recently viewed projects shortcuts */
.quick-nav-aside {
	margin-left: calc(var(--menu-item-h-padding) + 10px);
	border-left: 1px solid rgba(0, 0, 0, 0.1);
	padding-left: calc(var(--menu-link-icon-gap) + 5px);
	font-size: 0.9rem;	
}
/*group title like dropdown list items group separator*/
/* .quick-nav-aside .group-title {
	padding: 10px 0 8px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}
.quick-nav-aside .group-title::before, 
.quick-nav-aside .group-title::after {
	content: "";
	height: 1px;
	background-color: rgba(0, 0, 0, 0.1);
	width: 100%;
	flex: 1;
} */
.quick-nav-aside .group-title:after {
	max-width: 10px;
}
.quick-nav-link {
	padding-block: 6px;
}



/* patterns specific */
.ask-tava-response-wrap div:empty {
	padding-bottom: 30px;
}

/* notes list */
.note-unit {
	padding: 12px 16px;
	border-radius: 4px;
}

	.note-unit.highlighted {
		border: 1px solid var(--accent-color);
	}

.panel-btn {
	--panel-btn-min-height: 80px;
	--panel-btn-padding: 12px;
	--panel-btn-img-width: 70px;
	--panel-btn-txt-size: 1rem;
	
	display: flex;
	height: auto;
	min-height: var(--panel-btn-min-height);
	width: 100%;
	justify-content: space-between;
	align-items: center;
	flex-direction: row-reverse;
	gap: 10px;
	border-radius: 4px;
	padding: var(--panel-btn-padding);
	font-size: var(--panel-btn-txt-size);
}
.panel-btn.selected {
	background-color: var(--accent-color) !important;
}

.panel-btn .thumb-wrap {
	line-height: 0;
}
.panel-btn .thumb-wrap img {
	width: var(--panel-btn-img-width);
	height: auto;
}
.panel-btn .text-wrap {
	font-weight: bold;
	flex: 1 1 auto;
	white-space: break-spaces;
	text-align: left;
	padding-left: 6px;
}

.panel-btn.selected .text-wrap {
	color: white;
}

.panel-btn.disabled {
	background-color: var(--light-gray) !important;
}
.panel-btn.disabled .text-wrap {
	background-color: var(--txt-disabled) !important;
}

.mobile-view-mode .panel-btn {
	--panel-btn-min-height: 60px;
	--panel-btn-padding: 8px;	
	--panel-btn-img-width: 40px;
	--panel-btn-txt-size: .9rem;
}

/* ant-drawer mobile-context-wrap*/
.ant-drawer-content-wrapper:has(.ant-drawer-content.mobile-context-wrap) {
	height: auto !important;
}

.ant-drawer-content.mobile-context-wrap {
	border-radius: 6px 6px 0 0;
}
.ant-drawer-content.mobile-context-wrap .ant-drawer-header-close-only {
	display: none;
}
.ant-drawer-content.mobile-context-wrap .ant-drawer-body {
	padding: 0;
	border-radius: 6px 6px 0 0;
}
.ant-drawer-content.mobile-context-wrap .ant-drawer-body .link {
	display: flex;
	gap: 10px;
	align-items: center;
	padding: var(--mobile-context-wrap-link-padding);
	border-bottom: 1px solid var(--border-color);
	color: var(--main-txt);
}
.ant-drawer-content.mobile-context-wrap .ant-drawer-body .link:hover {
	background-color: var(--mobile-context-wrap-link-bgr-hover);
}


/* actions-wrap-pin */
.actions-wrap-pin {
	position: fixed;
	top: 90px;
	right: 0;
	z-index: 50;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

	.actions-wrap-pin .pin-btn {
		border-radius: var(--ant-border-radius-lg) 0 0 var(--ant-border-radius-lg);
		padding-right: 26px;
		font-size: var(--txt-large);
	}

		.actions-wrap-pin .pin-btn:hover {
			padding-right: 36px;
		}

/*<<*/

/******************** app footer ********************/
.app-footer {
	height: var(--app-footer-height);
	background-color: var(--footer-bgr);
	padding: 40px;
	text-align: center;
}
/*<<*/
/*<<*/
/******************** note text expand ********************/
.note-text-expandable {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

/*<<*/

/******************** ant d table ********************/
.hidden-row {
	display: none;
}
/*<<*/

/******************** comment component ********************/
.comment {
	display: flex;
	margin-bottom: 28px;
}

.comment-image-container {
	margin-right: 12px;
}

.comment-image-thumb {
	margin-top: 10px;
}

.comment-right-part {
	width: 100%;
}

.comment-bubble {
	background-color: var(--comment-bubble-bgr);
	padding: 10px 14px;
	border-radius: 12px;
}

/* write area, edit area - with border by default*/
.text-area-comment {
	border: 2px solid var(--comment-bubble-bgr);
	padding: 10px 14px;
	border-radius: 12px;
}

/* hide border when in comment bubble */
.comment-bubble .text-area-comment {
	border: none;
	padding: 0;
	border-radius: 0;
}

/* hide textarea outline in focus by default */
.text-area-comment textarea:focus-visible {
	outline: none;
}
/* highlight his parent when textarea is in focus */
.text-area-comment:has(textarea:focus-visible) {
	outline: 1px solid var(--accent-color);
	border-color: var(--accent-color);
}
/* hide the outline when it is in the comment bubble */
.comment-bubble .text-area-comment:has(textarea:focus-visible) {
	outline: none;
}


/**/
.comment-el-mention {
	background-color: var(--comment-el-mention-bgr) !important;
	margin-right: 4px;
	display: inline-block;
	padding: 0 4px;
	border-radius: 4px;
}
/**/

.comment-actions {
	display: flex;
	font-size: 12px;
	color: rgb(51, 51, 51);
	cursor: pointer;
	margin-top: 8px;
}

.comment-action {
	margin-left: 8px;
}

	.comment-action:hover {
		text-decoration: underline;
	}

.replies {
	margin-top: 20px;
}


/******************** Mobile Specific ********************/

.mobile-view-mode .ant-layout-content {	
	padding: 16px 16px var(--mobile-footer-height) 16px;
	overflow: clip;
}
.mobile-view-mode .ant-layout-content:has(.content-wrap-inner) {
	padding: 0;	
}
.mobile-view-mode .content-wrap-inner {
	background-image: url('/public/images/mobile-background.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100svh;
}

.mobile-footer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	background-color: var(--mobile-footer-bgr);
	height: var(--mobile-footer-height);
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	margin: 0;
	padding: 0;
	box-shadow: 0 -1px 0 1px rgba(0, 0, 0, 0.1);
	z-index: 100;
}
.mobile-footer .nav-btn {
	font-size: 1.8rem;
	cursor: pointer;
	padding: 20px;
}
.mobile-footer .nav-btn.selected {
	color: var(--accent-color);
	box-shadow: 0 4px 0 var(--accent-color) inset;
}
.mobile-footer .nav-btn.special-tava-icon {
	position: relative;
	min-width: 30px;
	min-height: 26px;
	background-image: url('/public/favicon.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding: 0;
	box-shadow: none;
}
.mobile-footer .nav-btn.special-tava-icon::before {
	transform: scale(0.6) translate(58%, -30%);
	position: absolute;
	background-color: #fff;
	border-radius: 100px;
	padding: 5px;
	top: 0;
	left: 0;
}
.mobile-footer .nav-btn.selected {
	color: #008060;
}

/* desktop text appereance animation delay specific */
.desktop-view-mode .txt-ani-entrance .ani-el {
--add-delay: .1s;
}

/**/

.ask-tava-box {
	background: rgba(255, 255, 255, 0.68);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5.4px);
	-webkit-backdrop-filter: blur(5.4px);
	padding: 2rem;
}

.mobile-view-mode .fix-to-bottom-wrap .ant-affix {
	bottom: var(--mobile-footer-height) !important;
	z-index: 30;
	height: auto !important;
}


/*user-dropdown mobile fix*/
.user-dropdown-wrap.ant-dropdown {
	width: 100% !important;
	inset: 0 !important;
	left: 0 !important;
	right: auto !important;
	top: auto !important;
	bottom: calc(var(--mobile-footer-height) - 3px) !important;
	
}
.user-dropdown-wrap.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
	--ant-dropdown-padding-block: 12px;
}
/**/
.list-item-row {
	gap: 10px;
}
.ant-list .ant-list-item.list-item-row {
	flex-wrap: nowrap; /*ant overwrite fix*/
}
.list-item-col {
	flex: 1 auto;
}

/*<<*/




.popup-compact {
	width: 800px !important;
}

/*  help-badge-btn - help popover trigger btn */

.help-badge-btn {
	position: fixed;
	left: 40px;
	bottom: 40px;
	border-radius: 100px;
	padding: 0;
	font-size: 1.4rem;
	min-width: 32px;
}
/*  help-badge-popover - help-badge-btn popover */
.help-badge-popover {
	max-width: 400px;
	position: fixed; /*ant fix*/
	--help-popover-indent:20px;
	--ant-popover-inner-padding: 20px !important;
	left: var(--help-popover-indent) !important;
	z-index: 100;
}

.help-badge-popover .ant-popover-arrow {
	margin-left: calc(var(--help-popover-indent) * -1);
}
.help-badge-popover .header-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	padding-bottom: 20px;
}
.help-badge-popover .middle-wrap {
	font-size: .9rem;
}
.help-badge-popover .bottom-wrap {
	font-size: .9rem;
	text-align: right;
	padding-top: 10px;
}
.help-margin-left {
	flex: 1;
	margin-left: 30px;
}


/* help aside nav theme specific */
/* .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title, .ant-menu-light > .ant-menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
	color: var(--ant-menu-sub-menu-item-selected-color);
} */
.vmenu-aside .ant-menu {
	--ant-menu-sub-menu-item-selected-color: var(--accent-color);
	--ant-menu-item-color: var(--accent-color);
}




/* temp - only for front end to hide the tool */
.tsqd-parent-container {
	display: none;
}


/*Project settings - \src\pages\settings\account\Home.tsx */
.personal-info.desktop-layout {
	display: flex;
	flex-direction: row;
	margin-top: 1rem;
	gap: 2rem;
}

.personal-info.mobile-layout {
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
}

.personal-info-header {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.personal-info-details {
	flex: 1;
}

.profile-picture {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: #ddd;
	height: 6rem;
	width: 6rem;
}


/*<<*/

/*HelpSider.tsx - \src\pages\help\components\HelpSider.tsx - 
The button that appears on mobile displays with small width to expand and collapse the menu - Nikola */

.ant-layout .ant-layout-sider-zero-width-trigger {
	top: 0;
	left: auto;
	transform: none;
	position: absolute;
	z-index: 10;
}

/******************** NoteForm.tsx Styles ********************/

.note-form-gallery-item {
    position: relative;
    width: 104px;
    height: 104px;
}

.note-form-gallery-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.note-form-image-actions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 50%;
}

.note-form-image-actions:hover {
    opacity: 1;
}

.note-form-add-image {
    width: 104px;
    height: 104px;
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #fafafa;
}

.note-form-add-image > div {
    margin-top: 8px;
}


/****** Media Queries Breakpoints >>> **************************************************************************/

/*custom xlarge*/
@media screen and (max-width: 1420px) {
	.help-badge-popover {
		max-width: 300px;
	}
  }
/*custom large*/
@media screen and (max-width: 1100px) {
	.app-aside {
		--app-aside-bar-width: 260px;
	}
  }