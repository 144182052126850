/* Partial SMT Library */


/*flex box model*/
 /* Basic styles */

 .f-box, .fbox, .hbox, .vbox, .cbox {
  display: flex;
}

.hbox {
   flex-direction: row;
   width: 100%; 
}

.vbox {
    flex-direction: column;
    height: 100%; 
}

.cbox {
    justify-content: center;
    width: 100%; 
}

.endbox {
    justify-content: flex-end;
    width: 100%;
}

.startbox {
    justify-content: flex-start; 
}
/**/

.topbox {
    align-items: flex-start; 
}

.bottombox {
    align-items: flex-end; 
}

.vcbox {
    align-items: center; 
}

.flex-wrap {
    flex-wrap: wrap; 
}

.flex-item {
  flex: 1 1 auto;
}

/**/

.non-box-item {
	flex: none;
}

/* flex childs self align */
.self-start {align-self: flex-start;}

.self-end {align-self: flex-end;}

.self-center {align-self: center;}

/*gaps - used in css grid or css flexbox*/
.gap-zero {gap: 0;}

.gap-05 {gap: 5px;}
.gap-10 {gap: 10px;}
.gap-15 {gap: 15px;}
.gap-20 {gap: 20px;}
.gap-25 {gap: 25px;}
.gap-30 {gap: 30px;}
.gap-35 {gap: 35px;}
.gap-40 {gap: 40px;}
.gap-45 {gap: 45px;}
.gap-50 {gap: 50px;}
.gap-55 {gap: 55px;}
.gap-60 {gap: 60px;}



/* display contents - when needed to skip flex child scope for this element */
.skip-tag {display: contents;}

/** <-endregion Layout */



  
  /*------------- max height in % ------------------*/
  .maxh-10 {max-height: 10% !important;}
  .maxh-20 {max-height: 20% !important;}
  .maxh-30 {max-height: 30% !important;}
  .maxh-40 {max-height: 40% !important;}
  .maxh-50 {max-height: 50% !important;}
  .maxh-60 {max-height: 60% !important;}
  .maxh-70 {max-height: 70% !important;}
  .maxh-80 {max-height: 80% !important;}
  .maxh-90 {max-height: 90% !important;}
  .maxh-100 {max-height: 100% !important;}
  
  /*-------------- max height in view port portions -----------------*/
  .maxvh-10 {max-height: 10vh !important;}
  .maxvh-20 {max-height: 20vh !important;}
  .maxvh-30 {max-height: 30vh !important;}
  .maxvh-40 {max-height: 40vh !important;}
  .maxvh-50 {max-height: 50vh !important;}
  .maxvh-60 {max-height: 60vh !important;}
  .maxvh-70 {max-height: 70vh !important;}
  .maxvh-80 {max-height: 80vh !important;}
  .maxvh-90 {max-height: 90vh !important;}
  .maxvh-100 {max-height: 100vh !important;}




/** Common **/

/*-element outer spaces-*/
.ml-0{margin-left:0 !important;}
.ml-02{margin-left:2px !important;}
.ml-05{margin-left:5px !important;}
.ml-10{margin-left:10px !important;}
.ml-15{margin-left:15px !important;}
.ml-20{margin-left:20px !important;}
.ml-25{margin-left:25px !important;}
.ml-30{margin-left:30px !important;}
.ml-40{margin-left:40px !important;}
.ml-50{margin-left:50px !important;}
.ml-60{margin-left:60px !important;}
.ml-70{margin-left:70px !important;}
.ml-80{margin-left:80px !important;}

.mr-0{margin-right:0 !important;}
.mr-02{margin-right:2px !important;}
.mr-05{margin-right:5px !important;}
.mr-10{margin-right:10px !important;}
.mr-15{margin-right:15px !important;}
.mr-20{margin-right:20px !important;}
.mr-25{margin-right:25px !important;}
.mr-30{margin-right:30px !important;}
.mr-40{margin-right:40px !important;}
.mr-50{margin-right:50px !important;}
.mr-60{margin-right:60px !important;}
.mr-70{margin-right:70px !important;}
.mr-80{margin-right:80px !important;}

.mt-0{margin-top:0 !important;}
.mt-02 {margin-top: 2px !important;}
.mt-05{margin-top:5px !important;}
.mt-10{margin-top:10px !important;}
.mt-15{margin-top:15px !important;}
.mt-20{margin-top:20px !important;}
.mt-25{margin-top:25px !important;}
.mt-30{margin-top:30px !important;}
.mt-40{margin-top:40px !important;}
.mt-50{margin-top:50px !important;}
.mt-60{margin-top:60px !important;}
.mt-70{margin-top:70px !important;}
.mt-80{margin-top:80px !important;}

.mb-0{margin-bottom:0 !important;}
.mb-02{margin-bottom:2px !important;}
.mb-05{margin-bottom:5px !important;}
.mb-10{margin-bottom:10px !important;}
.mb-15{margin-bottom:15px !important;}
.mb-20{margin-bottom:20px !important;}
.mb-25{margin-bottom:25px !important;}
.mb-30{margin-bottom:30px !important;}
.mb-40{margin-bottom:40px !important;}
.mb-50{margin-bottom:50px !important;}
.mb-60{margin-bottom:60px !important;}
.mb-70{margin-bottom:70px !important;}
.mb-80{margin-bottom:80px !important;}

.m-0{margin:0 !important;}
.m-02{margin:2px !important;}
.m-05{margin:5px !important;}
.m-10{margin:10px !important;}
.m-15{margin:15px !important;}
.m-20{margin:20px !important;}
.m-25{margin:25px !important;}
.m-30{margin:30px !important;}
.m-40{margin:40px !important;}
.m-50{margin:50px !important;}
.m-60{margin:60px !important;}
.m-70{margin:70px !important;}
.m-80{margin:80px !important;}

.h-offset-10 {margin-inline: -10px !important;}
.h-offset-15 {margin-inline: -15px !important;}
.h-offset-20 {margin-inline: -20px !important;}
.h-offset-25 {margin-inline: -25px !important;}
.h-offset-30 {margin-inline: -30px !important;}
.h-offset-35 {margin-inline: -35px !important;}
.h-offset-40 {margin-inline: -40px !important;}

/*-element inner spaces-*/
.pl-0{padding-left:0 !important;}
.pl-02{padding-left:2px !important;}
.pl-05{padding-left:5px !important;}
.pl-10{padding-left:10px !important;}
.pl-15{padding-left:15px !important;}
.pl-20{padding-left:20px !important;}
.pl-25{padding-left:25px !important;}
.pl-30{padding-left:30px !important;}
.pl-40{padding-left:40px !important;}
.pl-50{padding-left:50px !important;}
.pl-60{padding-left:60px !important;}
.pl-70{padding-left:70px !important;}
.pl-80{padding-left:80px !important;}

.pr-0{padding-right:0 !important;}
.pr-02{padding-right:2px !important;}
.pr-05{padding-right:5px !important;}
.pr-10{padding-right:10px !important;}
.pr-15{padding-right:15px !important;}
.pr-20{padding-right:20px !important;}
.pr-25{padding-right:25px !important;}
.pr-30{padding-right:30px !important;}
.pr-40{padding-right:40px !important;}
.pr-50{padding-right:50px !important;}
.pr-60{padding-right:60px !important;}
.pr-70{padding-right:70px !important;}
.pr-80{padding-right:80px !important;}

.pt-0{padding-top:0 !important;}
.pt-02{padding-top:2px !important;}
.pt-05{padding-top:5px !important;}
.pt-10{padding-top:10px !important;}
.pt-15{padding-top:15px !important;}
.pt-20{padding-top:20px !important;}
.pt-25{padding-top:25px !important;}
.pt-30{padding-top:30px !important;}
.pt-40{padding-top:40px !important;}
.pt-50{padding-top:50px !important;}
.pt-60{padding-top:60px !important;}
.pt-70{padding-top:70px !important;}
.pt-80{padding-top:80px !important;}

.pb-0{padding-bottom:0 !important;}
.pb-02{padding-bottom:2px !important;}
.pb-05{padding-bottom:5px !important;}
.pb-10{padding-bottom:10px !important;}
.pb-15{padding-bottom:15px !important;}
.pb-20{padding-bottom:20px !important;}
.pb-25{padding-bottom:25px !important;}
.pb-30{padding-bottom:30px !important;}
.pb-40{padding-bottom:40px !important;}
.pb-50{padding-bottom:50px !important;}
.pb-60{padding-bottom:60px !important;}
.pb-70{padding-bottom:70px !important;}
.pb-80{padding-bottom:80px !important;}

.p-0{padding:0 !important;}
.p-02{padding:2px !important;}
.p-05{padding:5px !important;}
.p-10{padding:10px !important;}
.p-15{padding:15px !important;}
.p-20{padding:20px !important;}
.p-25{padding:25px !important;}
.p-30{padding:30px !important;}
.p-40{padding:40px !important;}
.p-50{padding:50px !important;}
.p-60{padding:60px !important;}
.p-70{padding:70px !important;}
.p-80{padding:80px !important;}

/* margins auto - used for centering or pushing when flex aplied */
.m-auto {margin: auto;}
.mhor-auto {margin-left: auto; margin-right: auto;}
.mver-auto {margin-top: auto; margin-bottom: auto;}
.ml-auto {margin-left: auto;}
.mr-auto {margin-right: auto;}

/****************/
.full-w {width:100%; max-width:none;}
.full-width {width:100%;}
mobile-width {width: 80%;}


.full-h {height: 100%; max-height:none;}
.full-height {height: 100%;}

/** Text alignment */
.text-left {text-align: left !important;}
.text-right {text-align: right !important;}
.text-center {text-align: center !important;}
.text-justify {	text-align: justify !important;}

/** Vertical alignment */
.valign{vertical-align:middle !important;}

/** Bold */
.text-bold {font-weight: bold !important;}
.text-semibold {font-weight: 600 !important;}
.no-bold {font-weight: normal !important;}

/** Italic */
.text-italic {font-style: italic !important;}
.no-italic {font-style: normal !important;}

/** Underline */
.text-underline {text-decoration: underline !important;}

.no-underline {text-decoration: none !important;}

/** Text transfrom */
.text-lowercase {text-transform: lowercase !important;}
.text-uppercase {text-transform: uppercase !important;}
.no-transform {text-transform: none !important;}

/** Text differences */
.text-deleted {background: #ffdddd;}
.text-inserted {background: #ddffdd;}
.text-modified {background: #e4f2fd;}

/** Green text */
.text-green,
.text-success,
.text-positive {
	color: #339933 !important;
}

/** Blue text */
.text-blue,
.text-normal {
	color: #5a96d0 !important;
}

/** Orange text */
.text-orange,
.text-warning {color: #ff7901 !important;}

/** Pink text */
.text-pink {color: #db70db !important;}

/** Red text */
.text-red,
.text-error,
.text-negative {color: #f44c4f !important;}

/** Gray text */
.text-gray,
.text-disabled {color: #999999 !important;}

.text-dark-gray {color: #666666 !important;}
.text-light-gray {color: #bbbbbb !important;}

/** Flow */
.float-left {float: left;}
.float-right {float: right;}
.no-float {float: none;}

/** Flip */
.flip-hor {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}
.flip-ver {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
}


.v-align-top {
	vertical-align: top;
}
.v-align-middle {vertical-align:middle;}
.v-align-bottom {vertical-align:bottom;}

.width-auto, .f-box.width-auto {
	width: auto;
}


/**/
.required-ind:after {
	content:"\2a";
	color: #E50003;
	font-size:1.2em;
}


/*- overflow -*/
.overflow-hidden {overflow:hidden;}
.overflow-x-hidden {overflow-x:hidden;}
.overflow-y-hidden {overflow-y:hidden;}
.overflow-visible {overflow:visible;}
.overflow-auto {overflow:auto;}
.overflow-scroll {overflow: scroll;}



/*- txt-elipsis -*/ /*- works only for elements that have specified non-scalable width -*/
.txt-ellipsis {
	text-overflow:ellipsis;
	overflow:hidden;
}

.txt-nowrap {white-space: nowrap;}


/*sticky elements*/
.sticky-el {
    position: -webkit-sticky;
    position: sticky;
}
.sticky-el.st-0 {top: 0;}
.sticky-el.st-10 {top: 10px;}
.sticky-el.st-20 {top: 20px;}
.sticky-el.st-30 {top: 30px;}
.sticky-el.st-40 {top: 40px;}
.sticky-el.st-50 {top: 50px;}
.sticky-el.st-60 {top: 60px;}
.sticky-el.st-70 {top: 70px;}
.sticky-el.st-80 {top: 80px;}
.sticky-el.st-90 {top: 90px;}
.sticky-el.st-100 {top: 100px;}


.sticky-el.sb-0 {bottom: 0;}
.sticky-el.sb-10 {bottom: 10px;}
.sticky-el.sb-20 {bottom: 20px;}
.sticky-el.sb-30 {bottom: 30px;}
.sticky-el.sb-40 {bottom: 40px;}
.sticky-el.sb-50 {bottom: 50px;}
.sticky-el.sb-60 {bottom: 60px;}
.sticky-el.sb-70 {bottom: 70px;}
.sticky-el.sb-80 {bottom: 80px;}
.sticky-el.sb-90 {bottom: 90px;}
.sticky-el.sb-100 {bottom: 100px;}








/* -- ANIMATIONS St Lib -->> */

/* Text Entrance steps Animation */
.txt-ani-entrance {
	transform: scale(0.94);
	animation: entrance-scale 1.6s forwards cubic-bezier(0.5, 1, 0.89, 1);
  }
  @keyframes entrance-scale {
	100% { transform: scale(1);	}
  }
  
  .txt-ani-entrance .ani-el {
	display: inline-block;
	opacity: 0;
	filter: blur(4px);
	color: var(--txt-ani-entrance-color-1);

	/* animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0); */
	animation: entrance-fade-in 0.6s 0.1s forwards cubic-bezier(0, 0.56, 0.62, -0.04), entrance-fade-color 1.2s steps(3,end) forwards;
	/* animation: fade-in 0.8s steps(3,end) forwards; */
	--add-delay: .6s;
  }
  
  .ani-el:nth-child(1) {animation-delay: calc(0.1s + var(--add-delay)); }
  .ani-el:nth-child(2) {animation-delay: calc(0.2s + var(--add-delay)); }
  .ani-el:nth-child(3) {animation-delay: calc(0.3s + var(--add-delay)); }
  .ani-el:nth-child(4) {animation-delay: calc(0.4s + var(--add-delay)); }
  .ani-el:nth-child(5) {animation-delay: calc(0.5s + var(--add-delay)); }
  .ani-el:nth-child(6) {animation-delay: calc(0.6s + var(--add-delay)); }
  .ani-el:nth-child(7) {animation-delay: calc(0.7s + var(--add-delay)); }
  .ani-el:nth-child(8) {animation-delay: calc(0.8s + var(--add-delay)); }
  .ani-el:nth-child(9) {animation-delay: calc(0.9s + var(--add-delay)); }
  .ani-el:nth-child(10) {animation-delay: calc(1s + var(--add-delay)); }
  .ani-el:nth-child(11) {animation-delay: calc(1.1s + var(--add-delay)); }
  .ani-el:nth-child(12) {animation-delay: calc(1.2s + var(--add-delay)); }
  .ani-el:nth-child(13) {animation-delay: calc(1.3s + var(--add-delay)); }
  .ani-el:nth-child(14) {animation-delay: calc(1.4s + var(--add-delay)); }
  .ani-el:nth-child(15) {animation-delay: calc(1.5s + var(--add-delay)); }
  .ani-el:nth-child(16) {animation-delay: calc(1.6s + var(--add-delay)); }
  .ani-el:nth-child(17) {animation-delay: calc(1.7s + var(--add-delay)); }
  .ani-el:nth-child(18) {animation-delay: calc(1.8s + var(--add-delay)); }
  .ani-el:nth-child(19) {animation-delay: calc(1.9s + var(--add-delay)); }
  .ani-el:nth-child(20) {animation-delay: calc(2s + var(--add-delay)); }
  
  
  @keyframes entrance-fade-in {
	100% {
	  opacity: 1;
	  filter: blur(0);
	}
  }
  
  @keyframes entrance-fade-color {
	100% {
	  color: var(--txt-ani-entrance-color-2);
	}
  }



/* lib intro animations */
.ani-active {
	--lib-ani-duration: 1s;
  --lib-ani-delay: 0.25s;
  --lib-ani-repeat: 1;

  animation-duration: var(--lib-ani-duration);
  animation-fill-mode: both;
}

.ani-delay-none {
	animation-delay: 0s;
    animation-delay: calc(var(--lib-ani-delay)*0);
}
.ani-delay-1s {
	animation-delay: 1s;
    animation-delay: calc(var(--lib-ani-delay)*1);
}
.ani-delay-2s {
	animation-delay: 2s;
    animation-delay: calc(var(--lib-ani-delay)*2);
}
.ani-delay-3s {
	animation-delay: 3s;
    animation-delay: calc(var(--lib-ani-delay)*3);
}
.ani-delay-4s {
	animation-delay: 4s;
    animation-delay: calc(var(--lib-ani-delay)*4);
}
.ani-delay-5s {
	animation-delay: 5s;
    animation-delay: calc(var(--lib-ani-delay)*5);
}

/* bounce */
.ani-bounce {
    animation-name: lib-ani-bounce;
    transform-origin: center bottom;
}


@keyframes lib-ani-bounce {
	0%, 20%, 53%, 100% {
		animation-timing-function: cubic-bezier(.215,.61,.355,1);
		transform: translateZ(0);
	}
	40%, 43% {
		animation-timing-function: cubic-bezier(.755,.05,.855,.06);
		transform: translate3d(0,-30px,0) scaleY(1.1);
	}
	70% {
		animation-timing-function: cubic-bezier(.755,.05,.855,.06);
		transform: translate3d(0,-15px,0) scaleY(1.05);
	}
	80% {
		transform: translateZ(0) scaleY(.95);
		transition-timing-function: cubic-bezier(.215,.61,.355,1);
	}
	90% {
		transform: translate3d(0,-4px,0) scaleY(1.02);
	}
}

/* fade in up */
.ani-fadein-up {
    animation-name: lib-ani-fade-in-up;
}
@keyframes lib-ani-fade-in-up {
	0% {
		opacity: 0;
		transform: translate3d(0,50px,0);
	}

	100% {
		opacity: 1;
		transform: translateZ(0);
	}
}

/* pulse-shadow */
.ani-pulse-shadow {
    animation-name: lib-ani-pulse-shadow;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}
@keyframes lib-ani-pulse-shadow {
	0% {
		box-shadow: 0 0 0 0 var(--pulse-shadow-color);
	}

	100% {
		box-shadow: 0 0 10px 3px var(--pulse-shadow-color);
	}
}


/* <<-- ANIMATIONS St Lib -- */